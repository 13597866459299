/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useUser } from "contexts/Authentication"
import { Avatar, Dropdown, Menu, Typography } from "antd"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { DownOutlined, SettingFilled, HomeOutlined } from "@ant-design/icons"
import { getSalonPath } from "routes/utils"
import useSalonId from "hooks/useSalonId"
import { getUserInitials, getUsername } from "utils"
import useMediaQuery from "hooks/useMediaQuery"
import { elmaBreakpoints } from "styles/theme"
import { useSalonsQuery } from "actions/salons"
import { getSalonSettingsPath } from "routes/utils"

const UserDropdown = () => {
  const user = useUser()
  const { data: salons = [] } = useSalonsQuery()
  const salonId = useSalonId()
  const salonSettingsPath = getSalonSettingsPath(salonId)
  const isUnderMd = useMediaQuery(`(max-width: ${elmaBreakpoints.md}px)`)
  
  if (!salonId) {
    return null
  }

  let salonsSelect
  if (salons?.length > 1) {
    const currentSalon = salons.find((s) => s.id === salonId)

    salonsSelect = (
      <Menu.SubMenu icon={<HomeOutlined />} title={currentSalon.name}>
        {salons.map((salon) => (
          <Menu.Item key={salon.key}>
            <Link to={getSalonPath(salon.id)}>{salon.name}</Link>
          </Menu.Item>
        ))}
      </Menu.SubMenu>
    )
  } else {
    const currentSalon = salons[0]
    salonsSelect = (
      <Menu.Item icon={<HomeOutlined />} disabled>
        {currentSalon?.name}
      </Menu.Item>
    )

  }

  const menu = (
    <Menu>
      {salonsSelect}
      <Menu.Item icon={<SettingFilled />}>
        <Link to={salonSettingsPath}>Paramétres</Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={menu} icon={<DownOutlined />} placement='bottomRight'>
      <a
        className='ant-dropdown-link'
        style={{ lineHeight: "1" }}
        onClick={(e) => e.preventDefault()}>
        {!isUnderMd ? <Username>{getUsername(user)}</Username> : null}
        <UserAvatar>{getUserInitials(user)} </UserAvatar>
      </a>
    </Dropdown>
  )
}

const Username = styled(Typography.Text).attrs(() => ({ strong: true }))`
  color: var(--elma-primary);
`

const UserAvatar = styled(Avatar)`
  color: #fff;
  background-color: var(--elma-primary--light);
  margin-left: 8px;
`

export default UserDropdown
